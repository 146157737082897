<template>
  <div>
    <van-popup
      v-model="_ticketBoolean"
      closeable
      position="bottom"
      round
      :style="{ height: '60%' }">
      <div class="container">
        <p class="container-title">选择优惠券</p>
        
        <div class="container-kong" v-if="!coupons.length && !riceivedCoupons.length">
          <img class="container-kong-img" src="../img/kong2.png" alt="">
          <span>暂无优惠券</span>
        </div>

        <div class="tickerListBox" v-if="coupons.length || riceivedCoupons.length">
          <div class="tickerList" v-for="x in coupons" :key="x.id">
            <div class="tickerList-left">
              <div>
                <span style="font-size: 14px; color: #E54320;">{{ x.discountType == '0' ? x.discountRatio : '￥' }}</span>
                <span style="font-size: 20px; color: #E54320;">{{ x.discountType == '0' ? '折' : x.discountAmount }}</span>
              </div>
              <p style="font-size: 12px;">{{ x.name }}</p>

              <div class="tickerList-left-topYuan tickerList-left-yuan"></div>
              <div class="tickerList-left-botYuan tickerList-left-yuan"></div>
            </div>
            <div class="tickerList-right">
              <div class="tickerList-right-shuo">
                <p>{{ x.name }}</p>
                <p>限该店铺{{ x.ranges == '0' ? '内' : '指定' }}商品使用</p>
              </div>
              <div>
                <van-button round color="#E54320" size="mini" @click="selectTicket(x.id)">
                  <span style="padding: 0 5px;">领取</span>
                </van-button>
              </div>
            </div>
          </div>

          <p>本店可以用的优惠券</p>

          <div
            style="background: #EBEBEB; border: 1px solid transparent;"
            class="tickerList" 
            v-for="x in riceivedCoupons" :key="x.id" @click="selectTicket(x.id)">
            <div class="tickerList-left" style="border-right: 1px dashed #b7b7b7;">
              <div>
                <span style="font-size: 14px; color: #333;">{{ x.discountType == '0' ? x.discountRatio : '￥' }}</span>
                <span style="font-size: 20px; color: #333;">{{ x.discountType == '0' ? '折' : x.discountAmount }}</span>
              </div>
              <p style="font-size: 12px;">{{ x.discountType == '0' ? '折扣券' : '满减券' }}</p>

              <div class="tickerList-left-topYuan tickerList-left-yuan huiyuan huiyuanTop"></div>
              <div class="tickerList-left-botYuan tickerList-left-yuan huiyuan huiyuanBot"></div>
            </div>
            <div class="tickerList-right">
              <div class="tickerList-right-shuo">
                <p>{{ x.name }}</p>
                <p>限该店铺{{ x.ranges == '0' ? '内' : '指定' }}商品使用</p>
              </div>
              <div class="tickerList-right-img">
                <img src="../img/yilingqv.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { duihuanTicket } from "@/api/flMall"
import { Toast } from 'vant'
export default {
  props: {
    ticketBoolean: {
      type: Boolean,
      required: true
    },
    coupons: {
      type: Array,
      default: () => []
    },
    riceivedCoupons: {
      type: Array,
      required: true
    }
  },

  computed: {
    _ticketBoolean: {
      get () {
        return this.ticketBoolean
      },
      set () {
        this.$emit('closeTicket')
      }
    }
  },

  data () {
    return {
      id: ''
    }
  },

  methods: {
    selectTicket (id) {
      this._ticketBoolean = false
      duihuanTicket({ couponId: id }).then(res =>  {
        if (res.data.code === 0) {
          Toast('领取成功')
          this.$emit('updatePage')
        } else {
          Toast(res.data.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 15px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &-title {
    font-size: 18px;
    text-align: center;
  }
  &-kong {
    color: #F8DAB5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &-img {
      width: 120px;
    }
  }
}
.tickerListBox {
  margin: 15px 0;
  position: absolute;
  top: 42px;
  bottom: 0;
  left: 15px;
  right: 15px;
  overflow: auto;
}
.tickerListBox::-webkit-scrollbar {
  display: none;
}
.tickerList {
  border: 1px solid rgba(231,50,39,0.17);
  background: #FFF5F4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 13px 0;
  margin-bottom: 10px;
  &-left {
    width: 60px;
    padding: 0 15px;
    border-right: 1px dashed rgba(231,50,39,0.28);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-yuan {
      position: absolute;
      width: 14px;
      height: 7px;
      background: #fff;
      border: 1px solid rgba(231,50,39,0.17);
    }
    &-topYuan {
      top: -14px;
      right: -8px;
      border-radius: 0 0 7px 7px;
      border-top: 1px solid transparent;
    }
    &-botYuan {
      bottom: -14px;
      right: -8px;
      border-radius: 7px 7px 0 0;
      border-bottom: 1px solid transparent;
    }
  }
  &-right {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 10px;
    &-shuo {
      flex: 1;
      font-size: 12px;
    }
    &-img {
      width: 42px;
    }
  }
}
.huiyuan {
  border: 1px solid #EBEBEB;
}
.huiyuanTop {
  border-top: 1px solid transparent;
}
.huiyuanBot {
  border-bottom: 1px solid transparent;
}
</style>